const listCategoryCcnl = () => {
	/* const list = useStoreCcnlContract().getCnelCategorieList.map((categoria) => {
		const _ccnl = useStoreCcnlContract().getCcnlContractList.find(item => item.codice_settore === categoria.cat_tag)
		if (_ccnl) {
			return { label: categoria.desc, to: `/ccnl/${categoria.id}`, icon: '', img: elaboratePathIcon(categoria.desc) }
		}
	})
	console.log('listCategoryCcnl: ', list)
	return list */
	let idmenuRif = 0
	const list = useStoreCcnlContract().getCnelCategorieList.reduce((acc: any[]
		, categoria) => {
		const _ccnl = useStoreCcnlContract().getCcnlContractList.find(item => item.codice_settore === categoria.cat_tag)
		if (_ccnl) {
			acc.push({ label: `${categoria.cat_tag} - ${categoria.desc}`, to: '/ccnl', icon: '', img: elaboratePathIconMenu(categoria.desc), idmenuRif: ++idmenuRif })
		}
		return acc
	}, [])

	return list
}
export function useNavigationMenu() {
	const navigationMenu = () => {
		return [
			{
				// label: 'Menù',
				items: [{
					label: 'Dashboard',
					to: '/',
					img: '/images/CCNL_icone/home_icona_white.png'
					/* icon: 'pi pi-fw pi-home' */
				}, {
					label: 'C.C.N.L.',
					active: true,
					img: '/images/home_icone/CCNL_bottone_icona_white.png',
					items: [...listCategoryCcnl()]
				}, {
					label: 'Integrativi Regionali e Provinciali',
					to: '/ccnlreg',
					img: '/images/home_icone/ccnlregprov.png'
				},
				{
					label: 'Guide Pratiche CCNL', to: '/gplccnl', img: '/images/home_icone/gpllavoro_white.png'

				}, {
					label: 'Guide Pratiche Istituti', to: '/monografie', img: '/images/home_icone/guide_pratiche_istituti_white.png'
					// iconcustom: 'emojione-monotone:books'
					// img: '/images/home_icone/home_deleganoi_transparent_white.png'
				}, {
					label: 'Tabelle Accessorie', to: '/lavoro', img: '/images/home_icone/Aliquote_Bottone_Icona_white.png'

				},
				{
					label: 'Formulario Lavoro',
					to: 'https://www.orasilavora.it/',
					target: '_blank',
					img: '/images/home_icone/orasilavora_white.png'
				},
				{
					label: 'EQUO - Vertenze',
					to: 'https://equo.redigo.info/direcgi/esechtm?HTGETIN',
					target: '_blank',
					img: '/images/home_icone/equo_white.png'
				}
				/* {
					label: 'Formulario Lavoro',
					to: 'https://www.orasilavora.it/',
					/ img: '/images/home_icone/orasilavora_white.jpg', /
					target: '_blank',
					imageBig: '/images/home_icone/orasilavora_white.jpg'
				} */
				]
			}

			/* {
				label: 'PrimeVue',
				items: [
					{ label: 'DataTable', icon: 'pi pi-fw pi-table', to: '/prime/datatable' },
					{ label: 'Messages', icon: 'pi pi-fw pi-user-edit', to: '/prime/messages' },
					{ label: 'Validation', icon: 'pi pi-fw pi-user-edit', to: '/prime/validation' }
				]
			},
			{
				label: 'UI',
				items: [
					{ label: 'UnoCSS', icon: 'pi pi-fw pi-user-edit', to: '/ui/uno' },
					{ label: 'Icons', icon: 'pi pi-fw pi-user-edit', to: '/ui/icons' },
					{ label: 'TipTap', icon: 'pi pi-fw pi-user-edit', to: '/ui/tiptap' }
				]
			},
			{
				label: 'Content',
				items: [
					{ label: 'Markdown', icon: 'pi pi-fw pi-user-edit', to: '/cms/markdown' },
					{ label: 'Component', icon: 'pi pi-fw pi-user-edit', to: '/cms/component' }
				]
			},
			{
				label: 'Pages',
				items: [
					{ label: 'Stores', icon: 'pi pi-fw pi-database', to: '/stores' },
					{ label: 'Server', icon: 'pi pi-fw pi-database', to: '/server' },
					{ label: 'I18n', icon: 'pi pi-fw pi-database', to: '/i18n' }
				]
			},
			{
				label: 'Templates',
				items: [
					{ label: 'Blueprint', icon: 'pi pi-fw pi-user-edit', to: '/templates/blueprint' }
				]
			},
			{
				label: 'Menu Hierarchy',
				icon: 'pi pi-fw pi-search',
				items: [
					{
						label: 'Submenu 1',
						icon: 'pi pi-fw pi-bookmark',
						items: [
							{
								label: 'Submenu 1.1',
								icon: 'pi pi-fw pi-bookmark',
								items: [
									{ label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
									{ label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
									{ label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' }
								]
							},
							{
								label: 'Submenu 1.2',
								icon: 'pi pi-fw pi-bookmark',
								items: [
									{ label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' },
									{ label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark' }
								]
							}
						]
					},
					{
						label: 'Submenu 2',
						icon: 'pi pi-fw pi-bookmark',
						items: [
							{
								label: 'Submenu 2.1',
								icon: 'pi pi-fw pi-bookmark',
								items: [
									{ label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
									{ label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
									{ label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark' }
								]
							},
							{
								label: 'Submenu 2.2',
								icon: 'pi pi-fw pi-bookmark',
								items: [
									{ label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
									{ label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark' }
								]
							}
						]
					}
				]
			} */

		]
	}

	return { navigationMenu }
}
