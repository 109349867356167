import revive_payload_client_YPewJLW02z from "/app/node_modules/.pnpm/nuxt@3.8.0_@parcel+watcher@2.3.0_@types+node@20.8.6_encoding@0.1.13_eslint@8.51.0_optionator@_tmjuqzjh7udhkuipfxqvqiuq3a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_a3OOEa1yrw from "/app/node_modules/.pnpm/nuxt@3.8.0_@parcel+watcher@2.3.0_@types+node@20.8.6_encoding@0.1.13_eslint@8.51.0_optionator@_tmjuqzjh7udhkuipfxqvqiuq3a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5JjOsVlyZ3 from "/app/node_modules/.pnpm/nuxt@3.8.0_@parcel+watcher@2.3.0_@types+node@20.8.6_encoding@0.1.13_eslint@8.51.0_optionator@_tmjuqzjh7udhkuipfxqvqiuq3a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import posthog_client_R9xHuAjuA1 from "/app/node_modules/.pnpm/nuxt-posthog@1.5.0_rollup@3.29.4/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.mjs";
import payload_client_BJ0XeyA2ln from "/app/node_modules/.pnpm/nuxt@3.8.0_@parcel+watcher@2.3.0_@types+node@20.8.6_encoding@0.1.13_eslint@8.51.0_optionator@_tmjuqzjh7udhkuipfxqvqiuq3a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_0jJF7BzHmK from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@3.29.4_typescript@5.2.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_kooTNaAM51 from "/app/node_modules/.pnpm/nuxt@3.8.0_@parcel+watcher@2.3.0_@types+node@20.8.6_encoding@0.1.13_eslint@8.51.0_optionator@_tmjuqzjh7udhkuipfxqvqiuq3a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import directives_Olrkk8x749 from "/app/node_modules/.pnpm/nuxt-posthog@1.5.0_rollup@3.29.4/node_modules/nuxt-posthog/dist/runtime/plugins/directives.mjs";
import pdfmake_client_wMT7w3g1ok from "/app/node_modules/.pnpm/nuxt-pdfmake@0.0.19_rollup@3.29.4/node_modules/nuxt-pdfmake/dist/runtime/pdfmake.client.mjs";
import composition_gCtg4Kbegn from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_rollup@3.29.4_vue-router@4.2.5_vue@3.3.4__vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_3RfBlNtLAH from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_rollup@3.29.4_vue-router@4.2.5_vue@3.3.4__vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import plugin_zWKIxHVb6w from "/app/node_modules/.pnpm/@sfxcode+nuxt-primevue@1.4.1_esbuild@0.19.5_rollup@3.29.4_vite@4.5.0_@types+node@20.8.6_sass@_zpbtujelalunduwjrsve4igslq/node_modules/@sfxcode/nuxt-primevue/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/app/.nuxt/formkitPlugin.mjs";
import plugin_9aQEJwStsA from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.0-rc.0_encoding@0.1.13_next-auth@4.21.1_next@13.5.6_@babel+core@7.24._ianxo2htonwsvi2q4rxib4ytq4/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_QVRqLSpSUm from "/app/node_modules/.pnpm/nuxt@3.8.0_@parcel+watcher@2.3.0_@types+node@20.8.6_encoding@0.1.13_eslint@8.51.0_optionator@_tmjuqzjh7udhkuipfxqvqiuq3a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_hwMw68IKy0 from "/app/node_modules/.pnpm/nuxt@3.8.0_@parcel+watcher@2.3.0_@types+node@20.8.6_encoding@0.1.13_eslint@8.51.0_optionator@_tmjuqzjh7udhkuipfxqvqiuq3a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import directive_client_jS50AHLKqG from "/app/plugins/directive.client.ts";
import loaddata_client_zganBBiBNN from "/app/plugins/loaddata.client.ts";
import localStorage_client_h7jxRclGiK from "/app/plugins/localStorage.client.ts";
import markdownit_fsFV40uZ9C from "/app/plugins/markdownit.ts";
export default [
  revive_payload_client_YPewJLW02z,
  unhead_a3OOEa1yrw,
  router_5JjOsVlyZ3,
  posthog_client_R9xHuAjuA1,
  payload_client_BJ0XeyA2ln,
  plugin_vue3_0jJF7BzHmK,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kooTNaAM51,
  directives_Olrkk8x749,
  pdfmake_client_wMT7w3g1ok,
  composition_gCtg4Kbegn,
  i18n_3RfBlNtLAH,
  unocss_MzCDxu9LMj,
  plugin_zWKIxHVb6w,
  formkitPlugin_pZqjah0RUG,
  plugin_9aQEJwStsA,
  chunk_reload_client_QVRqLSpSUm,
  check_outdated_build_client_hwMw68IKy0,
  directive_client_jS50AHLKqG,
  loaddata_client_zganBBiBNN,
  localStorage_client_h7jxRclGiK,
  markdownit_fsFV40uZ9C
]